import { SkeletonItem } from 'components/skeleton-item'

export function SidebarTopicsListItemSkeleton() {
  return (
    <div className="group flex h-[40px] cursor-pointer flex-row gap-2 xl:h-[60px] xl:w-full xl:gap-4">
      <SkeletonItem className="rounded-2 aspect-[1] h-[40px] w-[40px] p-2 xl:h-[60px] xl:w-[60px] xl:p-[18px]" />
      <div className="flex h-auto flex-col justify-center gap-1">
        <SkeletonItem className="h-[20px] w-[66px]" />
        <SkeletonItem className="h-[20px] w-[112px]" />
      </div>
    </div>
  )
}
