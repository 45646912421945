import { Topic, DEFAULT_TOPIC_UI } from '@ask-cf/constants/topics'

export interface MergedTopicsType extends Omit<Topic, 'id'> {
  questionCount: number
  textIdentifier: string
  name: string
  id: string
}

export function mergeTopics(
  localData: Topic[],
  serverData: {
    questionCount: number
    textIdentifier: string
    id: string
    name: string
  }[],
) {
  const serverMap = new Map(serverData.map(item => [item.name, item]))

  // Only keep and update local topics that are found in the server data
  const mergedData = localData.reduce<MergedTopicsType[]>((acc, item) => {
    const serverDetails = serverMap.get(item.title)
    if (serverDetails) {
      acc.push({
        ...item,
        ...serverDetails,
        id: serverDetails.id,
      })
    }
    return acc
  }, [])

  // Add new topics from server data that are not in local data
  serverData.forEach(serverItem => {
    if (!mergedData.some(localItem => localItem.title === serverItem.name)) {
      mergedData.push({
        ...serverItem,
        ...DEFAULT_TOPIC_UI,
        title: serverItem.name,
        slug: serverItem.name.replace('&', 'and').toLowerCase().replace(/\s+/g, '-'),
        followers: 0,
      })
    }
  })

  return mergedData
}

export function mergeTrendingTopics(
  localData: Topic[],
  serverData: {
    questionCount: number
    textIdentifier: string
    id: string
    name: string
  }[],
): MergedTopicsType[] {
  const filteredServerData = serverData.filter(topic => topic.name !== 'Other')

  const topTrendingTopics = filteredServerData
    .sort((a, b) => b.questionCount - a.questionCount)
    .slice(0, 10)

  // Merge server data into local topics
  const mergedData = mergeTopics(localData, topTrendingTopics).sort(
    (a, b) => b.questionCount - a.questionCount,
  )

  return mergedData
}
